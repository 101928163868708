.slideText {
  color: rgb(39, 42, 47);
  font-family: AnticSlab;
  font-size: 6vw;
}

.MuiTab-root {
  font-family: "Montserrat";
  font-weight: bold;
}

.MuiTabs-indicator {
  background-color: #ff8800 !important;
}

.MuiTabs-flexContainer {
  height: 100%;
}

.footerPlayButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  padding-top: 5px;
  color: #ffffff;
  background-color: #455a64;
  transition: background-color 1000ms, color 500ms;
}

.footerPlayButton:hover {
  background-color: #2e3d44;
  color: #ff8800;
}

.leftMenuButton {
  transition: transform 0.2s !important;
}

.leftMenuButton:hover {
  transform: scale(1.02);
}
